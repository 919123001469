import { Platform } from 'react-native';

import { getEnvConfig } from '../utils/getEnvConfig';

const envConfig = getEnvConfig();

export const appVersion = envConfig.REACT_APP_VERSION ?? '';
export const baseUrl = envConfig.REACT_APP_API_MICROSERVICE_URL ?? '';
export const baseAssetsUrl = envConfig.REACT_APP_ASSETS_URL_TV ?? '';
export const baseTrackUrl = envConfig.REACT_APP_API_TRACK_URL ?? '';
export const envShortName = envConfig.REACT_APP_NODE_ENV_SHORT_NAME ?? '';
export const socketUrl = envConfig.REACT_APP_SOCKET_URL ?? '';
export const socketUrlProfile = envConfig.REACT_APP_SOCKET_URL_PROFILE ?? '';
export const getProgramApi = envConfig.REACT_APP_PROGRAMS_API ?? '';

export const datadogClientToken =
  (Platform.OS === 'web'
    ? envConfig.REACT_APP_WEB_DATADOG_CLIENT_TOKEN
    : envConfig.REACT_APP_NATIVE_DATADOG_CLIENT_TOKEN) ?? '';

export const datadogProjectId =
  (Platform.OS === 'web'
    ? envConfig.REACT_APP_WEB_DATADOG_PROJECT_ID
    : envConfig.REACT_APP_NATIVE_DATADOG_PROJECT_ID) ?? '';

// create-react-app will always resolve build to production env
// so to create a controlled **prod** environment even if production
// build always resolve NODE_ENV to "prod" envShortName variable was created.

export const isProductionEnv = () => {
  return envShortName === 'prod';
};

export const isDevelopmentEnv = () => {
  return envShortName === 'dev';
};

export const isTestEnv = () => {
  return process.env.NODE_ENV === 'test';
};
